'use client';

import ErrorPage from '@components/layouts/error-page/error-page';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import type { NextAppError } from '@royalaholddelhaize/ah-next-core/src/interfaces/next-app-error';
import type { FC } from 'react';

const RootError: FC<NextAppError> = () => {
    const { t } = useTranslations('error');

    return <ErrorPage title={t('default')} t={t} />;
};

export default RootError;
